import { useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { styled as MUIStyled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from '../../firebase/auth';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { Theme, getTimeSinceLastModified } from '../../constants';
import { ConditionReportPDF } from './condition_reports/ReportPDF';
import { topBarSidePaddingPerc, topBarHeight, navBarWidth } from '../layout/Layout';
import { GrZoomIn, GrZoomOut } from 'react-icons/gr';
import { isBrowser } from 'react-device-detect';
import { reportsTopBarHeight } from './ReportsPage';

interface Props {
  project: any;
  openNavBar: boolean;
  setOpenNavBar: React.Dispatch<React.SetStateAction<boolean>>;
  zoom: number;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
}

export const ReportsTopBar = ({project, openNavBar, setOpenNavBar, zoom, setZoom}: Props) => {
  const { organization } = useAuth();
  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);

  const downloadPDF = async () => {
    // https://github.com/diegomura/react-pdf/issues/736
    setIsDownloadingPDF(true);
    const blob = await pdf(<ConditionReportPDF { ...project} orgImagePath={organization?.imagePath}/>).toBlob();
    saveAs(blob, 'ConditionReport.pdf');
    setIsDownloadingPDF(false);
  };

  return (
    <AppBar position="fixed" open={openNavBar}>
      <IconButton 
        onClick={() => setOpenNavBar(true)} 
        sx={{...(openNavBar && {display: 'none'}), paddingLeft: 0, marginRight: 'auto'}}
      >
        <MenuIcon/>
      </IconButton>
      {project?.lastModified ? 
        <div style={{alignSelf: 'center', color: 'black', fontSize: '13px'}}>
          Last edited {getTimeSinceLastModified(project?.lastModified.seconds)}
        </div> : ''
      }
      {isBrowser ? 
        <>
          <IconButton onClick={() => setZoom(zoom => Math.max(zoom-0.1, 0.8))} sx={{padding: 0}}>
            <GrZoomOut/>
          </IconButton>
          <IconButton onClick={() => setZoom(zoom => zoom + 0.1)} sx={{padding: 0}}>
            <GrZoomIn/>
          </IconButton>
          <div style={{alignSelf: 'center', color: 'black', fontSize: '13px'}}>
            {`${Math.trunc(zoom*100)}%`}
          </div>
        </> : ''
      }
      <LoadingButton 
        size="small"
        variant="contained"
        loadingPosition="start"
        startIcon={<ShareOutlinedIcon/>}
        loading={isDownloadingPDF}
        sx={{maxHeight: '30px', alignSelf: 'center'}} 
        onClick={downloadPDF}
      >
        Generate PDF
      </LoadingButton>
    </AppBar>
  )
}


interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = MUIStyled(MuiAppBar, {shouldForwardProp: (prop) => prop !== 'open'})
  <AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: '10px',
  height: reportsTopBarHeight,
  top: topBarHeight,
  padding: `0 ${topBarSidePaddingPerc}%`,
  backgroundColor: Theme.colors.MidGray,
  boxShadow: 'none',
  ...(open && {
    width: `calc(100% - ${navBarWidth}%)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
