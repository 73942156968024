import { useState, FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { NavBar } from './NavBar';
import { TopBar } from './TopBar';
import { PrivateRoute } from './PrivateRoute';
import { Theme } from '../../constants';
import { isBrowser } from 'react-device-detect';

export const topBarSidePaddingPerc = 2;
export const topBarHeight = '6vh';
export const navBarWidth = isBrowser ? 20 : 50;
export const navBarSidePadding = 100 * topBarSidePaddingPerc / navBarWidth;

export const Layout: FunctionComponent = () => {
  const [isNavBarOpen, setIsNavBarOpen] = useState(false);

  return (
    <PrivateRoute>
      <TopBarContainer>
        <TopBar setIsNavBarOpen={setIsNavBarOpen}/>
      </TopBarContainer>
      <AppContainer>
        <BodyContainer>
          <NavBar isOpen={isNavBarOpen} setIsOpen={setIsNavBarOpen}/>
          <Outlet/>
        </BodyContainer>
      </AppContainer> 
    </PrivateRoute>
  );
}

const TopBarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: ${topBarHeight};
  padding: 0% ${topBarSidePaddingPerc}%;
  z-index: 1399;
  position: fixed;
  left: 0;
  right: 0;
  gap: 10px;
  background: ${Theme.colors.TheDarkest};
  color: ${Theme.colors.OffWhite};
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${topBarHeight}; /* to space the App explicitly below the fixed TopBar. */
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 250px;
`;