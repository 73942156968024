import {addDoc, setDoc, doc, deleteDoc, collection, query, getDoc, getDocs, updateDoc, DocumentReference, QueryConstraint} from 'firebase/firestore';
import { db } from './firebase';

export const createEmptyDocument = (collectionName: string): DocumentReference => {
    const docRef = doc(collection(db, collectionName));
    console.log(`Created empty project in ${collectionName} with ID: ${docRef.id}`);
    return docRef;
}

export const addData = (data: object, collectionName: string) => {
    addDoc(collection(db, collectionName), {...data, lastModified: new Date()});
}

export const updateData = (data: object, collectionName: string, docId: string) => {
    updateDoc(doc(db, collectionName, docId), {...data, lastModified: new Date()});
}

export const moveData = async (docId: string, fromCollection: string, toCollection: string) => {
    const docSnap = await getDoc(doc(db, fromCollection, docId));

    if (docSnap.exists()) {
        const data = JSON.parse(JSON.stringify(docSnap.data()));
        await setDoc(doc(db, toCollection, docId), data);
        await deleteDoc(doc(db, fromCollection, docId));
    } else {
        console.log(`DocId ${docId} doesn't exist in collection ${fromCollection}`);
    }
}

export const getDataFromFirestore = async (collectionName: string, constraint?: QueryConstraint) => {
    const queryRes = constraint ? query(collection(db, collectionName), constraint) : query(collection(db, collectionName));
    const querySnapshot = await getDocs(queryRes);

    let allData: any = []
    for (const documentSnapshot of querySnapshot.docs) {
        const data = documentSnapshot.data();
        allData.push({
            ...data,
            id: documentSnapshot.id,
        })
    }
    
    return allData;
}