import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { styled as MUIStyled } from '@mui/material/styles';
import styled from 'styled-components';
import { Theme, PROJECTS_COLLECTION } from '../../constants';
import { ReportsNavBar } from './ReportsNavBar';
import { ConditionReport } from './condition_reports/Report';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../firebase/firebase';
import { useSelectedProjectContext } from '../SelectedProjectContext';
import { navBarWidth } from '../layout/Layout';
import { isBrowser } from 'react-device-detect';
import { ReportsTopBar } from './ReportsTopBar';

export const reportsTopBarHeight = '6vh';

export const ReportsPage = () => {
  // inspiration: https://mui.com/material-ui/react-drawer/
  const [openNavBar, setOpenNavBar] = useState(isBrowser ? true : false);
  const { selectedProject, setSelectedProject } = useSelectedProjectContext();
  const navigate = useNavigate();
  const [zoom, setZoom] = useState(1.0);
  const [project, setProject] = useState<any>();

  useEffect(() => {
    let unsub = () => {};

    if (selectedProject) {
      unsub = onSnapshot(doc(db, PROJECTS_COLLECTION, selectedProject.id), (doc) => setProject(doc.data()));
    } else navigate('/');

    return () => {
      setSelectedProject(undefined);
      unsub();
    };
  }, []);

  return (
    <ReportsPageContainer>
      <ReportsTopBar
        project={project}
        openNavBar={openNavBar}
        setOpenNavBar={setOpenNavBar}
        zoom={zoom}
        setZoom={setZoom}
      />
      <ReportsNavBar open={openNavBar} setOpen={setOpenNavBar}/>
      <Main open={openNavBar} sx={{transform: `scale(${zoom})`, transformOrigin: 'top'}}>
        <ConditionReport/>
      </Main>
    </ReportsPageContainer>
  );
}

const ReportsPageContainer = styled.div`
  display: flex;
  position: fixed;
  background-color: ${Theme.colors.MidGray};
  height: calc(100% - ${reportsTopBarHeight});
  width: 100vw;
  overflow-y: scroll;
`;

const Main = MUIStyled('div', {shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  marginTop: reportsTopBarHeight,
  width: '100%',
  overflowX: 'hidden',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${navBarWidth}%`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));
