import { KeyboardEvent, MouseEvent, FunctionComponent, ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import AppsIcon from '@mui/icons-material/Apps';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { homePath, trashPath} from '../../App';
import { navBarWidth as width, topBarHeight, navBarSidePadding as sidePadding } from './Layout';

interface PathObject {
  name: string;
  icon: ReactElement
  path: string;
}

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NavBar: FunctionComponent<Props> = ({isOpen, setIsOpen}) => {
  const { pathname } = useLocation();
  const padding = {paddingLeft: `${sidePadding}%`, paddingRight: `${sidePadding}%`};
  const pathObjects: PathObject[] = [
    {name: 'Home', icon: <FolderOpenIcon/>, path: homePath},
    {name: 'Trash', icon: <DeleteOutlineOutlinedIcon/>, path: trashPath},
  ]
  
  // adapted from https://mui.com/material-ui/react-drawer/#temporary-drawer
  const toggleDrawer = (open: boolean) =>
    (event: KeyboardEvent | MouseEvent) => {
      if (event.type === 'keydown' && ['Tab', 'Shift'].includes((event as KeyboardEvent).key)) {
        return;
      }
      setIsOpen(open);
    };

  return (
    <Drawer open={isOpen} onClose={toggleDrawer(false)} sx={{zIndex: 1400, '& .MuiDrawer-paper': {width: `${width}%`}}}>
      <Box onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
        <Toolbar style={{minHeight: 0, height: topBarHeight, ...padding}}>
          <IconButton sx={{padding: 0}} color="inherit">
            <AppsIcon/>
          </IconButton>
        </Toolbar>
        <List sx={{padding: 0}}>
          {pathObjects.map((pathObj) => (
            <StyledLink key={pathObj.name} to={pathObj.path}>
              <ListItemButton sx={padding} selected={pathObj.path === pathname}>
                <ListItemIcon>
                  {pathObj.icon}
                </ListItemIcon>
                <ListItemText primary={pathObj.name} />
              </ListItemButton>
            </StyledLink>
          ))}
        </List>
        <Divider/>
      </Box>
    </Drawer>
  );
}

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: unset;
`;
