import { useState, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';
import { Theme } from '../../constants';
import { Button, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useAuth } from '../../firebase/auth';
import { PROJECTS_COLLECTION, Project, getUserRole} from '../../constants';
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from '../../firebase/firebase';
import { ItemThumbnail } from './ItemThumbnail';
import { CreateOrEditDialog } from './dialogs/CreateOrEditDialog';
import { DeleteDialog } from './dialogs/DeleteDialog';
import { ShareDialog } from './dialogs/share_dialog/ShareDialog';
import { SearchField } from './SearchField';


export const ProjectsPage: FunctionComponent = () => {
  const [openCreate, setOpenCreate] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [projects, setProjects] = useState<Project[]>([]);
  const [sharedProjects, setSharedProjects] = useState<Project[]>([]);
  const { authUser } = useAuth();
  const isMobile = useMediaQuery('(max-width:550px)');

  useEffect(() => {
    let unsub1 = () => {};
    let unsub2 = () => {};

    if (authUser) {
      const colRef = collection(db, PROJECTS_COLLECTION)

      unsub1 = onSnapshot(query(colRef, where('uid', '==', authUser.uid)), (querySnapshot) => {
        const _projects: Project[] = querySnapshot.docs.map((doc: any) => {return {...doc.data(), id: doc.id}});
        setProjects(_projects);
      });

      unsub2 = onSnapshot(query(colRef, where('uid', '!=', authUser.uid)), (querySnapshot) => {
        const _projects: Project[] = querySnapshot.docs.map((doc: any) => {return {...doc.data(), id: doc.id}});
        setSharedProjects(_projects.filter(
          project => project?.projectPermissions && authUser.uid in project?.projectPermissions
        ));
      });
    }

    return () => {unsub1(); unsub2();}
  }, []);

  const generateItemGrid: Function = (projects: Project[], altMessage: string) => {
    if (!authUser) return undefined;
    if (projects.length === 0) return <h4 style={{paddingLeft: '15px'}}>{altMessage}</h4>
    
    return projects.filter(
        project => JSON.stringify(project).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
      ).map(project => {
        const userRole = getUserRole(authUser.uid, project);
        return <ItemThumbnail 
          project={project} 
          setOpenShare={userRole === 'owner' ? setOpenShare : undefined} 
          setOpenEdit={userRole === 'owner' ? setOpenEdit : undefined }
          setOpenDelete={userRole === 'owner' ? setOpenDelete : undefined}
          key={project.id}
        />
      }) 
  }

  return (
    <ProjectsPageContainer>
      <ProjectsTopBar>
        <Heading>My Projects</Heading>
        <SearchField setSearchTerm={setSearchTerm}/>
      </ProjectsTopBar>
      <ItemGrid>
        {searchTerm == '' ? 
          <Button startIcon={<AddIcon/>} variant="outlined" color="secondary" onClick={() => setOpenCreate(true)}
            sx={{width: isMobile ? 'clamp(150px, 49.75vw, 180px)' : '150px', height: '150px', padding: '5px'}} 
          >
            New Project
          </Button> : ''
        }
        {generateItemGrid(projects, "Please Create a project to start using the platform")}
      </ItemGrid>
      {sharedProjects.length > 0 ? <Heading>Projects shared with me</Heading> : ''}
      <ItemGrid>
        {generateItemGrid(sharedProjects, "No projects shared with you at the moment")}
      </ItemGrid>
      {openShare ? <ShareDialog open={openShare} setOpen={setOpenShare}/>: ''}
      <CreateOrEditDialog open={openCreate} setOpen={setOpenCreate}/>
      <CreateOrEditDialog open={openEdit} setOpen={setOpenEdit}/>
      <DeleteDialog open={openDelete} setOpen={setOpenDelete}/>
    </ProjectsPageContainer>
  );
};

export const ProjectsPageContainer = styled.div`
  background: ${Theme.colors.OffWhite};
  width: 100vw;
  min-height: 100vh;
  padding-left: 2vw;
  padding-right: 2vw;
`;

export const ProjectsTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
  column-gap: 10px;
  padding-top: 10px;
`;

export const ItemGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-top: 10px;
  gap: clamp(10px, 1vw, 20px);
`;

export const Heading = styled.h3`
  align-self: center;
`;
