import { useState, createContext, useContext } from 'react';
import { Project } from '../constants';

interface SelectedProjectContextType {
  selectedProject: Project | undefined;
  setSelectedProject: React.Dispatch<Project | undefined>;
}

const SelectedProjectContext = createContext<SelectedProjectContextType>({
  selectedProject: undefined, 
  setSelectedProject: (project: Project | undefined) => project
});

export const SelectedProjectProvider = ({ children }: {children: React.ReactNode}) => {
  const [selectedProject, _setSelectedProject] = useState<Project | undefined>();
  const setSelectedProject = (project: Project | undefined) => _setSelectedProject(project);

  return (
    <SelectedProjectContext.Provider value={{selectedProject, setSelectedProject}}>
      {children}
    </SelectedProjectContext.Provider>
  );
};

export const useSelectedProjectContext = () => useContext(SelectedProjectContext);