import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { useSelectedProjectContext } from '../SelectedProjectContext';
import { TRASH_COLLECTION, PROJECTS_COLLECTION } from '../../constants';
import { moveData } from '../../firebase/firestore';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RestoreDialog = ({open, setOpen}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { selectedProject, setSelectedProject } = useSelectedProjectContext();

  const handleRestore = async () => {
    setIsLoading(true);
    await moveData(selectedProject!.id, TRASH_COLLECTION, PROJECTS_COLLECTION);
    console.log(`Restored ${selectedProject!.id} back to the ${PROJECTS_COLLECTION} collection.`);
    setIsLoading(false);
    onClose();
  }

  const onClose = () => {
    setOpen(false); 
    if (selectedProject) {
      setSelectedProject(undefined);
    }
  }

  return (
    <Dialog open={open} onClose={onClose} >
      <DialogTitle>Do you want to restore this project?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Restoring this project will move it back to the home page and make it possible to edit again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        { isLoading ? 
          <LoadingButton loading loadingPosition="start" startIcon={<RestoreFromTrashIcon/>} variant="outlined">
            Restore
          </LoadingButton> :
          <Button type="submit" variant="contained" startIcon={<RestoreFromTrashIcon/>} onClick={handleRestore}>
            Restore
          </Button> 
        }
      </DialogActions>
    </Dialog>
  )
}
