import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button, useMediaQuery } from '@mui/material';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import AppsIcon from '@mui/icons-material/Apps';
import { Theme } from '../../constants';
import { useAuth } from '../../firebase/auth';
import { homePath } from '../../App';

interface Props {
  setIsNavBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TopBar: FunctionComponent<Props> = ({setIsNavBarOpen}) => {
  const { authUser, signOut } = useAuth();
  const showEmail = useMediaQuery('(min-width: 440px)')
  
  return (
    <>
      <IconButton sx={{paddingLeft: '0'}} color='inherit' onClick={() => setIsNavBarOpen(open => !open)}>
        <AppsIcon/>
      </IconButton>
      <StyledLink to={homePath}>Artnresto</StyledLink>
      {showEmail ? <p style={{fontSize: '0.9em'}}>{authUser?.email}</p> : ''}
      <Button size="small" variant="contained" onClick={signOut}>Logout</Button>
    </>
  );
}

const StyledLink = styled(Link)`
  color: ${Theme.colors.OffWhite};
  text-decoration: unset;
  margin-right: auto;
  font-size: 1.5rem;
`;