import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import { Project } from '../../constants';

interface ProjectDataField {
  field: 'registrationNumber' | 'title' | 'artist' |  'year' | 'other';
  fieldShown: string;
}
const DATA_TO_SHOW: ProjectDataField[] = [
  {field: 'registrationNumber', fieldShown: 'Registration Number'},
  {field: 'title', fieldShown: 'Title'},
  {field: 'artist', fieldShown: 'Artist'},
  {field: 'year', fieldShown: 'Year'},
  {field: 'other', fieldShown: 'Other'}
]

interface Props {
  open: boolean;
  anchorEl: HTMLElement | undefined;
  project: Project;
}

export const ItemThumbnailPopper = ({open, anchorEl, project}: Props) => {
  return (
    <Popper open={open} anchorEl={anchorEl} placement="bottom-start" transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper sx={{padding: '0 4px'}}>
            {DATA_TO_SHOW.map((data, idx) => {
              if (data.field in project) {
                return (
                  <Typography key={`typography-${idx}`} variant="subtitle2" sx={{ p: 0.1 }}>
                    <b>{data.fieldShown}</b> : {project[data.field]}
                  </Typography>
              )} else {
                return ''
              }
            })}
          </Paper>
        </Fade>
      )}
    </Popper>
  );
}