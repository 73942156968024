import { DialogContentText } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import { blue } from '@mui/material/colors';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { ACCESS_ROLES } from '../../../../constants';
import { User } from './ShareDialog';

interface Props {
  users: User[];
  setPermissionedUsers: React.Dispatch<React.SetStateAction<User[]>>;
  setOtherUsers: React.Dispatch<React.SetStateAction<User[]>>;
  setChangesPending: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModifyUserRoles = ({users, setPermissionedUsers, setOtherUsers, setChangesPending}: Props) => {
  const roleMapping = ACCESS_ROLES.map(
    (role: string) => ({value: role, label: role.charAt(0).toUpperCase() + role.slice(1)})
  );
  
  return (
    <>
    <DialogContentText>
      People with access
    </DialogContentText>
    <List sx={{ pt: 0 }}>
      {users.map((user, idx) => (
        <ListItemButton disableGutters key={user.email}>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={user.email} />
          <Select variant="standard" disableUnderline size="small" value={user.role} onChange={(event: any) => {
            if (event.target.value === 'remove') {
              setPermissionedUsers(prevUsers => prevUsers.filter(prevUser => prevUser.uid !== user.uid));
              setOtherUsers(prevUsers => [...prevUsers, user]);
            }
            else {
              setPermissionedUsers(prevUsers => {
                const newUsers = [...prevUsers];
                newUsers[idx].role = event.target.value
                return newUsers
              });
            }
            setChangesPending(true);
          }}>
            {roleMapping.map((role) => (
              <MenuItem value={role.value} key={`option-${role.value}`}>{role.label}</MenuItem>
            ))}
            <Divider/>
            <MenuItem value="remove">Remove</MenuItem>
          </Select>
        </ListItemButton>
      ))}
    </List>
    </>
  )
}