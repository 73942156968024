import { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, signOut as authSignOut, UserInfo } from 'firebase/auth';
import { auth, db } from './firebase';
import { doc, updateDoc, where } from "firebase/firestore"; 
import { getDataFromFirestore } from './firestore';
import { USERS_COLLECTION, ORGANIZATIONS_COLLECTION } from '../constants';

interface Organization {
  id: string;
  name: string;
  imagePath?: string;
}

/* hacky */
interface AuthUserInferface {
  authUser: UserInfo | null;
  isLoading: boolean;
  signOut: any;
  organization: Organization | undefined;
  setOrganization: React.Dispatch<React.SetStateAction<Organization | undefined>>;
}

const AuthUserContext = createContext<AuthUserInferface>({
  authUser: null,
  isLoading: true,
  signOut: () => {},
  organization: undefined,
  setOrganization: () => {},
});

export default function useFirebaseAuth() {
  const [authUser, setAuthUser] = useState<UserInfo | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [organization, setOrganization] = useState<Organization>();

  const clear = () => {
    setAuthUser(null);
    setIsLoading(false);
  };

  const signOut = () => authSignOut(auth).then(clear);

  useEffect(() => {
    const authStateChanged = async (user: UserInfo | null) => {
      setIsLoading(true);
      if (!user) {
        clear();
        return;
      }
      setAuthUser(user);
      updateDoc(doc(db, USERS_COLLECTION, user.uid), { email: user.email, uid: user.uid, lastSignedIn: new Date()});
      
      const userDoc = await getDataFromFirestore(USERS_COLLECTION, where('uid', '==', user.uid));
      const orgId = userDoc[0]?.organizationId
      if (orgId) {
        const org: Organization = (await getDataFromFirestore(ORGANIZATIONS_COLLECTION, where('__name__', '==', orgId)))[0];
        setOrganization(org);
      }

      setIsLoading(false);
    };
    const unsubscribe = onAuthStateChanged(auth, authStateChanged);
    return () => unsubscribe();
  }, []);

  return {
    authUser,
    isLoading,
    signOut,
    organization,
    setOrganization,
  };
}

export const AuthUserProvider = ({ children }: {children: React.ReactNode;}) => {
  const auth = useFirebaseAuth();
  return (
    <AuthUserContext.Provider value={auth}>{children}</AuthUserContext.Provider>
  );
};

export const useAuth = () => useContext(AuthUserContext);