import { FunctionComponent, useState } from 'react';
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { Theme, Project } from '../../constants';
import * as AIIcons from 'react-icons/ai';
import { FiShare2 } from "react-icons/fi"
import { MdOutlineRestoreFromTrash, MdOutlineDeleteOutline } from 'react-icons/md'
import { useSelectedProjectContext } from '../SelectedProjectContext';
import { ItemThumbnailPopper } from './ItemThumbnailPopper';
import { BrowserView, isBrowser } from 'react-device-detect';

interface Props {
  project: Project,
  setOpenShare?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDelete?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenRestore?: React.Dispatch<React.SetStateAction<boolean>>;
  blockNavigation?: boolean;
};

export const ItemThumbnail: FunctionComponent<Props> = (
  {project, setOpenShare, setOpenEdit, setOpenDelete, setOpenRestore, blockNavigation}
) => {
  const [openActionPanel, setOpenActionPanel] = useState(isBrowser ? false : true);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const navigate = useNavigate();
  const { setSelectedProject } = useSelectedProjectContext();
  const url = project.imagePath ?? '';
  const actionPanelButtons = [
    {stateFunc: setOpenRestore, icon: <MdOutlineRestoreFromTrash size={20}/>},
    {stateFunc: setOpenShare, icon: <FiShare2 size={19}/>},
    {stateFunc: setOpenEdit, icon: <AIIcons.AiOutlineEdit size={20}/>},
    {stateFunc: setOpenDelete, icon: <MdOutlineDeleteOutline size={20}/>},
  ];
  
  const handleItemThumbnailClick = () => {
    setSelectedProject(project);
    if (!blockNavigation) {
      navigate(`/reports/${project.id}`);
    }
  }

  const handleMouseLeave = () => {
    if (isBrowser) setOpenActionPanel(false);
  }

  return (
    <ItemThumbnailContainer 
      onClick={handleItemThumbnailClick} 
      onMouseEnter={(e: React.MouseEvent<HTMLElement>) => {setOpenActionPanel(true); setAnchorEl(e.currentTarget);}}
      onMouseLeave={handleMouseLeave}
    >
      <ImageContainer url={url}>
        {openActionPanel ?
          <ActionPanelContainer onClick={(e: any)=> {e.stopPropagation(); setSelectedProject(project);}}>
            {actionPanelButtons.filter(({stateFunc, icon}) => stateFunc).map(({stateFunc, icon}, idx) => (
              <IconDiv key={`icon-div-${idx}`} onClick={() => stateFunc!(true)}>{icon}</IconDiv>
            ))}
          </ActionPanelContainer>: ''
        }
      </ImageContainer> 
      <ItemDescription>
        <li><strong>Title:</strong> {project.title}</li>
        <li><strong>Artist:</strong> {project.artist}</li>
      </ItemDescription>
      <BrowserView>
        <ItemThumbnailPopper open={openActionPanel} anchorEl={anchorEl} project={project}/>
      </BrowserView>
    </ItemThumbnailContainer>
  )
};

const ItemThumbnailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 150px;
  height: 150px;
  @media (max-width: 550px) {
    width: clamp(150px, 49.75vw, 180px);
  }
  :hover {
    -webkit-filter: brightness(90%); 
  }
`;

const ImageContainer = styled.div<{ url: string }>`
  background-image: url(${({ url }) => url});
  height: 67%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 7px 7px 0px 0px;
`;

const ActionPanelContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  background: ${Theme.colors.LightGray};
  margin-left: auto;
  border-radius: 0px 5px 0px 5px;
`;

const IconDiv = styled.div`
  background: ${Theme.colors.LightGray};  
  color: ${Theme.colors.TheDarkest};
  border-radius: 5px;
  :hover {
    -webkit-filter: brightness(90%); 
  }
`

const ItemDescription = styled.ul`
  height: 33%;
  background: ${Theme.colors.LightGray};
  color: ${Theme.colors.TheDarkest};
  border-radius: 0px 0px 7px 7px;
  overflow: auto;
  list-style: none;
  font-size: 13px;
  padding-left: 5px;
  margin: 0;
`;