import { createTheme } from "@mui/material";

// TO DO: dark theme.

export const Theme = {
  colors: {
    TheDarkest: '#3A3A3A',
    DarkGray: '#484848',
    Gray: '#585858',
    MidGray: '#666666',
    MidLightGray: '#979494',
    MidLightLightGray: '#747373',
    LightGray: '#D9D9D9',
    OffWhite: '#F1EEEE',
    Green: '#CBF70D',
    TransparentGreen: '#CBF70D',
    Main: '#5D62F9'
  },
};

export const PROJECTS_COLLECTION = 'projects';
export const USERS_COLLECTION = 'users';
export const TRASH_COLLECTION = 'trash';
export const ORGANIZATIONS_COLLECTION = 'organizations';

export const ACCESS_ROLES = ['viewer', 'editor', 'owner'];
export type AccessRole = 'viewer' | 'editor' | 'owner';

// https://bareynol.github.io/mui-theme-creator/
// https://mui.com/material-ui/customization/color/
// https://mui.com/material-ui/customization/palette/
// https://m2.material.io/resources/color/
// https://mui.com/material-ui/customization/dark-mode/
export const muiThemeOverride = createTheme({
  palette: {
    primary: {
      // If only main provided:
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      main: Theme.colors.Main,
      contrastText: "#ffffff",
    },
    secondary: {
      main: Theme.colors.Gray
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      "'Fira Sans'",
      "'Droid Sans'",
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
  },
});

export interface ProjectData {
  //Project data
  registrationNumber: string;
  title: string;
  artist: string;
  year: number;
  other?: string;
  imagePath: string;

  // Condition report data
  auxilliarySupport?: string;
  condition?: string;
  dimentionsOfPaintingAndFrame: string;
  frameHangingHardware?: string;
  frameDefects?: string;
  frameDimentions?: string;
  frameInscriptions?: string;
  framePeriodicStyleAttribution?: string;
  framePreviousRepairs?: string;
  frameTechniqueAndMaterial?: string;
  frontLayer?: string;
  groundLayer?: string;
  inscriptions?: string;
  institutionOrOrigin?: string;
  support?: string;
  surfaceCoating?: string;
  artworkFrontImagePath?: string;
  artworkFrontDescription?: string;
  artworkBackImagePath?: string;
  artworkBackDescription?: string;
}

export interface Project extends ProjectData{
  id: string;
  uid: string;
  
  // User permissions
  projectPermissions?: object;

  // timestamp when the project data was last modified.
  lastModified: {seconds: number, nanoseconds: number};
}

export const getUserRole = (userId: string, project: Project, debug?: boolean): AccessRole | undefined => {
  //given a userId and project, returns the user's role.
  const permissions = project?.projectPermissions
  let role: AccessRole | undefined = undefined;

  if ((permissions) && (userId in permissions)) {
    role = permissions[userId as keyof typeof permissions];
  } else if (userId === project.uid) {
    role = 'owner';
  }

  if (debug) {
    console.log(`User ID: ${userId} has role: ${role} on project ${project.id}`);
  }

  return role;
}

export const getTimeSinceLastModified = (seconds: number) => {
  const secondsSinceLastMod = (Date.now() - seconds*1000) / 1000

  if (secondsSinceLastMod < 60) {
    return `seconds ago`
  } else if (secondsSinceLastMod < 3600) {
    return `${Math.trunc(secondsSinceLastMod/60)} minutes ago`
  } else if (secondsSinceLastMod < 86400) {
    return `${Math.trunc(secondsSinceLastMod/3600)} hours ago`
  } else if (secondsSinceLastMod < 2629756.8) {
    return `${Math.trunc(secondsSinceLastMod/86400)} days ago`
  } else if (secondsSinceLastMod < 31536000) {
    return `${Math.trunc(secondsSinceLastMod/2629756.8)} months ago`
  } else {
    return `${Math.trunc(secondsSinceLastMod/31536000)} years ago`
  }
}
