import { useState, useEffect, useRef } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import emailjs from '@emailjs/browser';
import { getDataFromFirestore, updateData } from '../../../../firebase/firestore';
import { where } from 'firebase/firestore';
import { useAuth } from '../../../../firebase/auth';
import { useSelectedProjectContext } from '../../../SelectedProjectContext';
import { PROJECTS_COLLECTION, USERS_COLLECTION, AccessRole, Project } from '../../../../constants';
import { UserSelect } from './UserSelect';
import { ModifyUserRoles } from './ModifyUserRoles';

// TO DO: make decision re sending email notifications. https://www.emailjs.com/pricing/
// interesting link: https://react.email/docs/getting-started/automatic-setup

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface User {
  id: string;
  uid: string;
  email: string;
  lastSignedIn?: object;
  role?: AccessRole
}

export const ShareDialog = ({open, setOpen}: Props) => {
  const { selectedProject, setSelectedProject } = useSelectedProjectContext();
  const ogPerms = useRef<any>({}); //OG permissions, before the changes.
  const [permissionedUsers, setPermissionedUsers] = useState<User[]>([]);
  const [otherUsers, setOtherUsers] = useState<User[]>([]);
  const [changesPending, setChangesPending] = useState(false);
  const { authUser, organization } = useAuth();

  useEffect(() => {
    const setStates = async () => {
      const users: User[] = (await getDataFromFirestore(USERS_COLLECTION, where('organizationId', '==', organization?.id)))
        .filter((user: User) => user.uid !== authUser?.uid);
      
      const projectData: Project[] = await getDataFromFirestore(
        PROJECTS_COLLECTION, where('__name__', '==', selectedProject!.id)
      );
      const projPerms = projectData[0].projectPermissions;
      ogPerms.current = projPerms;
      
      if (projPerms) {
        const doesUserHavePermissions = (user: User) => Object.keys(projPerms).includes(user.id);
        let permissionedUsers = users.filter(user => doesUserHavePermissions(user));
        const otherUsers = users.filter(user => (!doesUserHavePermissions(user) && user.id !== projectData[0].uid));

        if (permissionedUsers.length > 0) {
          permissionedUsers = permissionedUsers.map(user => (
            {...user, role: projPerms[user.id as keyof typeof projPerms]}
          ))
        }
        
        setPermissionedUsers(permissionedUsers);
        setOtherUsers(otherUsers);
      }
      else {
        setOtherUsers(users);
      }
    }
    setStates();
  }, []);
  
  const handleClose = () => {
    setOpen(false); 
    if (selectedProject) {
      setSelectedProject(undefined);
    }
  };

  const sendEmailNotifications = (toEmail: string, role?: string) => {
    const ROLE_CHANGE_TEMPLATE = 'template_8yghky5';
    const ROLE_REMOVAL_TEMPLATE = 'template_eqwf2br'
    const ROLE_DESCRIPTIONS: any = {
      viewer: 'view data about the artwork, and associated reports.',
      editor: 'edit data about the artwork, and associated reports, and add reports.',
      owner: 'edit data about the artwork, and associated reports, add reports, share and delete the project.'
    };

    let templateParams: any = {
      toEmail: toEmail,
      documentName: `${selectedProject!.title} by ${selectedProject!.artist}`,
    };

    if (role) {
      templateParams.fromEmail = authUser?.email;
      templateParams.role = role;
      templateParams.roleDescription = ROLE_DESCRIPTIONS[role];
    }

    emailjs.send('service_k1l1hj3', role ? ROLE_CHANGE_TEMPLATE : ROLE_REMOVAL_TEMPLATE, templateParams, 'ZiJmWcNkHRZO9H1P7')
      .then((result) => console.log(`${result.text}. Sent ${role ? '': 'permission removal'} email to ${toEmail}`),
            (error) => console.log(error.text)
    );
  }
  
  const handleShare = () => {
    let permissions: any = {}; //{id1, "viewer", ...}
    permissionedUsers.forEach((user: any) => permissions[user.id] = user.role);
    const data = JSON.parse(JSON.stringify({projectPermissions: permissions}));
    updateData(data, PROJECTS_COLLECTION, selectedProject!.id);
    handleClose();
    
    // Send emails to users with new/updated permissions.
    /*permissionedUsers.filter((user: any) => (
      !(user.id in ogPerms.current) || (user.id in ogPerms.current && ogPerms.current[user.id] !== user.role)
      )).forEach((user: any) => (
        sendEmailNotifications(user.email, user.role)
    ));
    
    // Send emails to users who have lost permissions.
    if (Object.keys(ogPerms).length > 0) {
      console.log('Some users have permissions from before, will run checks.')
      for (const [userId, role] of Object.entries(ogPerms.current)) {
        if (!permissionedUsers.find((user: any) => user.id === userId)) {
          const userRes = otherUsers.find((user: User) => user.id === userId);
          sendEmailNotifications(userRes!.email);
        }
      };
    }*/
  }

  return (
    <Dialog open={open} onClose={handleClose} >
      <DialogTitle>Share Project "{selectedProject?.title}"</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select the people you want to share the project with and the relevant permissions.
        </DialogContentText>
        <UserSelect
          setPermissionedUsers={setPermissionedUsers}
          otherUsers={otherUsers}
          setOtherUsers={setOtherUsers}
          setChangesPending={setChangesPending}
        />
        {permissionedUsers.length > 0 ? 
          <ModifyUserRoles 
            users={permissionedUsers}
            setPermissionedUsers={setPermissionedUsers}
            setOtherUsers={setOtherUsers}
            setChangesPending={setChangesPending}
          /> : ''
        }
      </DialogContent>
      <DialogActions>
        {changesPending ? <DialogContentText><i>Pending changes</i></DialogContentText> : ''}
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" startIcon={<ShareIcon />} onClick={handleShare}>Share</Button>
      </DialogActions>
    </Dialog>
  )
}