import { useState, FunctionComponent } from 'react';
import { Theme } from '../../../constants';
import { uploadFile } from '../../../firebase/storage';
import { useAuth } from '../../../firebase/auth';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useSelectedProjectContext } from '../../SelectedProjectContext';

interface Props {
  setChangesCounter: React.Dispatch<React.SetStateAction<number>>;
}

export const CreateReportButton: FunctionComponent<Props> = ({setChangesCounter}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { authUser } = useAuth();
  const { selectedProject } = useSelectedProjectContext();
  
  const handleFileUpload = async (event: any) => {
    const file = (event.target as HTMLInputElement).files![0]
    if (event?.target && file) {
      setIsLoading(true);
      await uploadFile(file, authUser!.uid, selectedProject!.id, file.name);
      setIsLoading(false);
      setChangesCounter((changesCounter: number) => changesCounter + 1);
    }
    else {
      console.log('File not uploaded');
    }
  };
  
  return (
    <IconButton component="label" disabled={isLoading}>
      <AddCircleIcon sx={{color: Theme.colors.Green}}/>
      <input 
        id="project-file-upload" 
        type="file" 
        hidden
        accept="application/msword, application/pdf"
        onChange={handleFileUpload}
      />
    </IconButton>
  )
}
