import { FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom'
import { StyledFirebaseAuth } from 'react-firebaseui';
import { EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../firebase/firebase';
import { useAuth } from '../firebase/auth';
import { homePath } from '../App';

export const LoginPage: FunctionComponent = () => {
  const { authUser } = useAuth();
  const uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: homePath,
    signInOptions: [
      GoogleAuthProvider.PROVIDER_ID,
      EmailAuthProvider.PROVIDER_ID,
    ]
  }

  return authUser ? <Navigate to={homePath}/> : (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <h1 style={{alignSelf: 'center', padding: '2.5% 0 10% 0'}}>Welcome to Artnresto</h1>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth}/>
    </div>
  )
}