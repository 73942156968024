import styled from 'styled-components';
import { Button } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';

// TO DO: add image removal icon when uploaded.

interface Props {
  filePath: string | undefined;
  handleImagePick: (event: any) => void;
  styles?: object;
  label?: string;
  disabled?: boolean;
}
export const ImageUpload = ({filePath, handleImagePick, styles, label, disabled}: Props) => {
  return (
    <Button color="secondary" component="label" sx={styles}>
      <ImageContainer url={filePath}>
        <input hidden accept="image/jpeg, image/png" type="file" onChange={handleImagePick} disabled={disabled}/>
        {filePath ? '': <><PhotoCamera/>{label ? <h6 style={{margin: 0}}>{label}</h6>: ''}</>}
      </ImageContainer>
    </Button>
  );
};

const ImageContainer = styled.div<{ url: string | undefined }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${({ url }) => url});
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  :hover {
    -webkit-filter: brightness(90%); 
  }
`;