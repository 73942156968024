import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthUserProvider } from './firebase/auth'
import { SelectedProjectProvider } from './components/SelectedProjectContext';
import { ThemeProvider } from '@mui/material/styles';
import { muiThemeOverride } from './constants';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/* Removed </React.StrictMode> to get authentication to work
details of bug here: https://github.com/firebase/firebaseui-web-react/issues/59 */
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
    <AuthUserProvider>
      <SelectedProjectProvider>
        <BrowserRouter>
          <ThemeProvider theme={muiThemeOverride}>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </SelectedProjectProvider>
    </AuthUserProvider>
);
reportWebVitals();
