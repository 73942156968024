import { Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';

// TO DO: Clean up styles and formatting. Sync with report.
// same with interface

export interface ConditionReportData {
  orgImagePath: string;

  imagePath: string;
  registrationNumber: string;
  institutionOrOrigin: string;
  artist: string;
  title: string;
  year: number;
  dimentionsOfPaintingAndFrame: string;
  inscriptions: string;
  condition: string;
  support: string;
  auxilliarySupport: string;
  frontLayer: string;
  groundLayer: string;
  surfaceCoating: string;
  framePeriodStyleAttribution: string;
  frameTechniqueAndMaterial: string;
  frameDimentions: string;
  frameHangingHardware: string;
  frameInscriptions: string;
  frameDefects: string;
  framePreviousRepairs: string;
  artworkFrontImagePath: string;
  artworkFrontDescription: string;
  artworkBackImagePath: string;
  artworkBackDescription: string;
}

export const ConditionReportPDF = (props: ConditionReportData) => {
  // https://react-pdf.org/components
  // https://www.npmjs.com/package/@react-pdf/renderer
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header} >
          <View style={styles.headerLeft}>
            {props.orgImagePath ? <Image style={styles.orgLogo} src={props.orgImagePath}/>: <></>}
            <Text style={styles.h4}>Condition Report</Text>
          </View>
          <View style={styles.headerRight}>
            <Image style={styles.projectImage} src={props.imagePath}/>
          </View>
        </View>
      <Text style={styles.sectionHeading}>Identification of painting</Text>
      <Text style={styles.fieldDesc}>1. Accession, collection registration number: {props.registrationNumber}</Text>
      <Text style={styles.fieldDesc}>2. Institution or origin: {props.institutionOrOrigin}</Text>
      <Text style={styles.fieldDesc}>3. Artist or attribution: {props.artist}</Text>
      <Text style={styles.fieldDesc}>4. Title: {props.title}</Text>
      <Text style={styles.fieldDesc}>5. Signature, date or period: {props.year}</Text>
      <Text style={styles.fieldDesc}>6. Dimentions of painting and frame: {props.dimentionsOfPaintingAndFrame}</Text>
      <Text style={styles.fieldDesc}>7. Inscriptions and identifying marks: {props.inscriptions}</Text>
      <Text style={styles.fieldDesc}>8. Condition: {props.condition}</Text>
      <Text style={styles.divider}/>
      <Text style={styles.sectionHeading}>Description (method of application, condition, and previous repairs)</Text>
       
      <Text style={styles.fieldDesc}>9. Back of object</Text>
      <View style={styles.indentedSubSection}>
        <Text style={styles.fieldDesc}>9.1 Support (long): {props.support}</Text>
        <Text style={styles.fieldDesc}>9.2 Auxilliary Support: {props.auxilliarySupport}</Text>
      </View> 

      <Text style={styles.fieldDesc}>10. Front of object</Text>
      <View style={styles.indentedSubSection}>
        <Text style={styles.fieldDesc}>10.1 Front layer: {props.frontLayer}</Text>
        <Text style={styles.fieldDesc}>10.2 Ground layer: {props.groundLayer}</Text>
        <Text style={styles.fieldDesc}>10.3 Surface Coating: {props.surfaceCoating}</Text>
      </View>
      <Text style={styles.pageNumber} render={({pageNumber}) => pageNumber}/>
     </Page>

     <Page size="A4" style={styles.page}>
      <Text style={styles.sectionHeading}>Description of frame</Text>
      <Text style={styles.fieldDesc}>11. Period style attribution: {props.framePeriodStyleAttribution}</Text>
      <Text style={styles.fieldDesc}>12. Technique and material: {props.frameTechniqueAndMaterial}</Text>
      <Text style={styles.fieldDesc}>13. Dimentions: {props.frameDimentions}</Text>
      <Text style={styles.fieldDesc}>14. Hanging hardware: {props.frameHangingHardware}</Text>
      <Text style={styles.fieldDesc}>15. Inscriptions labels, identifying marks: {props.frameInscriptions}</Text>
      <Text style={styles.fieldDesc}>16. Defects: {props.frameDefects}</Text>
      <Text style={styles.fieldDesc}>17. Previous repairs: {props.framePreviousRepairs}</Text>
      
      <Text style={styles.pageNumber} render={({pageNumber}) => pageNumber}/>
     </Page>

     <Page size="A4" style={styles.page}>
      <Text style={styles.sectionHeading}>Images</Text>
      <View style={styles.imageContainer}>
        <View style={{width: '45%'}}>
          {props.artworkFrontImagePath ? <Image style={{width: '100%', height: '65%'}} src={props.artworkFrontImagePath}/>: <></>}
          <Text style={styles.fieldDesc}>18.1 Front of image info {props.artworkFrontDescription}</Text>
        </View>
        <View style={{width: '45%'}}>
          {props.artworkBackImagePath ? <Image style={{width: '100%', height: '65%'}} src={props.artworkBackImagePath}/>: <></>}
          <Text style={styles.fieldDesc}>18.2 Back of image info {props.artworkBackDescription}</Text>
        </View>
      </View>

      <Text style={styles.pageNumber} render={({pageNumber}) => pageNumber}/>
     </Page>

   </Document>
  );
};


const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    padding: 25,
    fontSize: 7.5
  },
  
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '20%'
  },

  headerLeft: {width: '50%'},
  headerRight: {display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '50%'},
  orgLogo: {height: 'auto', width: 'auto', maxWidth: '40%'},
  h4: {fontSize: 18, padding: '14 0'},
  sectionHeading: {fontSize: 14, padding: '2 0'},
  projectImage: {height: 'auto', width: 'auto', maxWidth: '70%'},
  indentedSubSection: {paddingLeft: '2%'},

  fieldDesc: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0.5% 0 0 2%',
    fontSize: 12,
  },
  
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
  
  divider: {padding: '1% 0'},

  imageContainer: {
    height: '45%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  }
 
 });