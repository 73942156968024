import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Theme } from '../../../constants';
import { ORGANIZATIONS_COLLECTION, PROJECTS_COLLECTION, getUserRole } from '../../../constants';
import { uploadFile, uploadOrgFile, getDownloadURL } from '../../../firebase/storage';
import { useAuth } from '../../../firebase/auth';
import { updateData } from '../../../firebase/firestore';
import { useSelectedProjectContext } from '../../SelectedProjectContext';
import { TextField, RadioField } from '../InputFields';
import { ImageUpload } from '../../ImageUpload';
import { isBrowser } from 'react-device-detect';


export const ConditionReport = () => {
  const { authUser, organization, setOrganization } = useAuth();
  const { selectedProject } = useSelectedProjectContext();
  const userRole = useRef( 
    (authUser && selectedProject) ? getUserRole(authUser!.uid, selectedProject) : 'viewer'
  );
  const [orgImagePath, setOrgImagePath] = useState<string>(organization?.imagePath ?? '');
  const [artworkFrontImagePath, setArtworkFrontImagePath] = useState<string>();
  const [artworkBackImagePath, setArtworkBackImagePath] = useState<string>();

  useEffect(() => {
    if (selectedProject?.artworkFrontImagePath) {
      setArtworkFrontImagePath(selectedProject.artworkFrontImagePath)
    }
    if (selectedProject?.artworkBackImagePath) {
      setArtworkBackImagePath(selectedProject.artworkBackImagePath)
    }
  }, []);

  const handleOrgImagePick = async (event: any) => {
    const readInFiles = (event?.target as HTMLInputElement).files;
    console.log(organization);
    if (organization && readInFiles && readInFiles.length > 0) {
      setOrgImagePath(URL.createObjectURL(readInFiles[0]));
      const _imagePath = await getDownloadURL(await uploadOrgFile(readInFiles[0], organization.id, "orgImage"));
      updateData({imagePath: _imagePath}, ORGANIZATIONS_COLLECTION, organization.id);
      setOrganization({...organization, imagePath: _imagePath})
    }
  };

  const handleImagePick = async (event: any, imageName: string, setImagePath: Function) => {
    const readInFiles = (event?.target as HTMLInputElement).files;
    if (selectedProject && authUser && readInFiles && readInFiles.length > 0) {
      setImagePath(URL.createObjectURL(readInFiles[0]));
      const _imagePath = await getDownloadURL(await uploadFile(readInFiles[0], authUser.uid, selectedProject.id, imageName));
      updateData({[imageName]: _imagePath}, PROJECTS_COLLECTION, selectedProject.id);
    }
  };
  
  return (
    <>
      <ReportPage>
        <header style={{display: 'flex', justifyContent: 'space-between', height: '20%', gap: '10px'}}>
          <div style={{width: '50%'}}>
            <ImageUpload 
              filePath={orgImagePath}
              handleImagePick={handleOrgImagePick}
              styles={{width: '50%', height: '50%', padding: 0}}
              label="Organization Image"
              disabled={userRole.current === 'viewer'}
            />
            <h2 style={{marginTop: 0}}>Condition Report</h2>
          </div>
          <ProjectImageContainer url={selectedProject ? selectedProject?.imagePath: ''}/>
        </header>
        <SectionHeading>Identification of painting</SectionHeading>
        <Indentation>
          <TextField descriptor="1. Accession, collection registration number" fieldName="registrationNumber"/>
          <TextField descriptor="2. Institution or origin" fieldName="institutionOrOrigin"/>
          <TextField descriptor="3. Artist or attribution" fieldName="artist"/>
          <TextField descriptor="4. Title" fieldName="title"/>
          <TextField descriptor="5. Signature, date or period" fieldName="year"/>
          <TextField descriptor="6. Dimentions of painting and frame" fieldName="dimentionsOfPaintingAndFrame"/>
          <TextField descriptor="7. Inscriptions and identifying marks" fieldName="inscriptions"/>
          <RadioField descriptor="8. Condition" fieldName="condition" choices={['Excellent', 'Good', 'Fair', 'Poor', 'Very Poor']}/>
        </Indentation>  
        <SectionHeading>Description (method of application, condition, and previous repairs) </SectionHeading>
          <SubSectionHeading style={{marginTop: 0}}>9. Back of object</SubSectionHeading>
          <Indentation amount='0.8em'>
            <TextField descriptor="9.1 Support" fieldName="support" rows={2}/>
            <TextField descriptor="9.2 Auxilliary Support" fieldName="auxilliarySupport" rows={2}/>
          </Indentation>
          <SubSectionHeading>10. Front of object</SubSectionHeading>
          <Indentation amount='0.8em'>
            <TextField descriptor="10.1 Front layer" fieldName="frontLayer" rows={2}/>
            <TextField descriptor="10.2 Ground layer" fieldName="groundLayer" rows={2}/>
          </Indentation>
          <Footer>1</Footer>
      </ReportPage>
      
      <ReportPage>
        <Indentation amount='0.8em'>
          <TextField descriptor="10.3 Surface coating" fieldName="surfaceCoating" rows={4}/>
        </Indentation>
        <SectionHeading>Description of frame </SectionHeading>
        <Indentation>
          <TextField descriptor="11. Period style attribution" fieldName="framePeriodStyleAttribution"/>
          <TextField descriptor="12. Technique and material" fieldName="frameTechniqueAndMaterial" rows={3}/>
          <TextField descriptor="13. Dimentions" fieldName="frameDimentions"/>
          <TextField descriptor="14. Hanging hardware" fieldName="frameHangingHardware"/>
          <TextField descriptor="15. Inscriptions labels, identifying marks" fieldName="frameInscriptions" rows={3}/>
          <TextField descriptor="16. Defects" fieldName="frameDefects" rows={4}/>
          <TextField descriptor="17. Previous repairs" fieldName="framePreviousRepairs" rows={4}/>
        </Indentation>
        <Footer>2</Footer>
      </ReportPage>

      <ReportPage>
        <SectionHeading>Images</SectionHeading>
        <ImagesContainer>
          <ImageAndTextContainer>
            <ImageUpload 
              filePath={artworkFrontImagePath}
              handleImagePick={event => handleImagePick(event, "artworkFrontImagePath", setArtworkFrontImagePath)}
              styles={imageStyles}
              label="Front of Image"
              disabled={userRole.current === 'viewer'}
            />
            <TextField descriptor="18.1 Front of image info" fieldName="artworkFrontDescription" rows={6}/>
          </ImageAndTextContainer>
          <ImageAndTextContainer>
            <ImageUpload 
              filePath={artworkBackImagePath}
              handleImagePick={event => handleImagePick(event, "artworkBackImagePath", setArtworkBackImagePath)}
              styles={imageStyles}
              label="Back of Image"
              disabled={userRole.current === 'viewer'}
            />
            <TextField descriptor="18.2 Back of image info" fieldName="artworkBackDescription" rows={6}/>
          </ImageAndTextContainer>
        </ImagesContainer>
        <Footer>3</Footer>
      </ReportPage>
    </>
  );
};

const ReportPage = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto 30px auto;
  padding: 2.5%;
  background: white;
  aspect-ratio: 210 / 297;
  ${isBrowser ? 'height: 90%; min-height: 90%;' : 'width: 95%; min-width: 95%;'}
  font-size: ${isBrowser ? '75%' : '2.5vw'}; // not sure why 75% works lol :D
`;

const Indentation = styled.section<{ amount?: string }>`
  display: flex;
  flex-direction: column;
  row-gap: 0.2em;
  margin-left: ${({ amount }) => amount ?? '0.4em'};
`;

const ProjectImageContainer = styled.div<{ url: string }>`
  display: flex;
  justify-content: flex-end;
  width: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${({ url }) => url});
`;

const SectionHeading = styled.h4`
  margin: 0.5em 0 0.2em 0;
`;

const SubSectionHeading = styled.div`
  margin: 0.2em 0 0.2em 0.4em;
`;

const Footer = styled.div`
  text-align: center;
  margin-top: auto;
`;

const ImagesContainer = styled.div`
  height: 45%;
  display: flex;
  justify-content: space-around;
  margin-top: 2%;
`

const ImageAndTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1%;
  width: 45%;
`

const imageStyles = {
  width: '100%',
  height: '65%',
  border: `0.08em solid ${Theme.colors.LightGray}`,
  borderRadius: '0.2em',
  padding: 0
}