import { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import styled from 'styled-components';
import { Theme } from "../../constants";
import { PROJECTS_COLLECTION, ProjectData, getUserRole } from '../../constants';
import { useAuth } from "../../firebase/auth";
import { useSelectedProjectContext } from '../SelectedProjectContext';
import { updateData } from '../../firebase/firestore';
import { isBrowser } from 'react-device-detect';

// TO DO: solve radio buttons on mobile
// use the on snapshot thing here (so that users can simultaneously work on projects)
// Types assertions, data assertions etc.

interface TextFieldProps {
  descriptor: string;
  fieldName: string;
  rows: number;
}

export const TextField = ({descriptor, fieldName, rows}: TextFieldProps) => {
  const { selectedProject } = useSelectedProjectContext();
  const { authUser } = useAuth();
  const { register, handleSubmit } = useForm();
  const disabled = useRef(authUser && selectedProject ? 
    getUserRole(authUser!.uid, selectedProject) === 'viewer' : false 
  );
  
  const onSubmit = async (data: any) => {
    if (data[fieldName]) {
      updateData(data, PROJECTS_COLLECTION, selectedProject!.id);
      console.log(`Updated doc ID ${selectedProject!.id} with ${JSON.stringify(data)}`);
    }
    else {
      console.log(`${fieldName} not submitted as value is undefined.`)
    }
  };

  const onError = () => console.log('To implement');

  return (
    <Container rows={rows}>
      {descriptor}
      <StyledInput
        key={`project-form-input-${fieldName}`}
        disabled={disabled.current}
        rows={rows}
        defaultValue={selectedProject ? selectedProject[fieldName as keyof ProjectData] : ''}
        {...register(fieldName)}
        onBlur={handleSubmit(onSubmit, onError)}
      />
    </Container>
  );
};

TextField.defaultProps = {
  rows: 1
}

interface RadioInputProps {
  descriptor: string;
  fieldName: string;
  choices: string[];
}

export const RadioField = ({descriptor, fieldName, choices}: RadioInputProps) => {
  const { selectedProject } = useSelectedProjectContext();
  const { authUser } = useAuth();
  const [ checked, setChecked ] = useState(selectedProject ? 
    selectedProject[fieldName as keyof ProjectData] : undefined
  );
  const disabled = useRef(authUser && selectedProject ? 
    getUserRole(authUser!.uid, selectedProject) === 'viewer' : false 
  );
  const radioStyles = useRef(isBrowser ? 
    {marginTop: '-0.2em'} : {margin: '-0.2em 0.2em 0 0.3em'}
  )

  const onSubmit = (e: any) => {
    const data = {[fieldName]: e.target.value};
    updateData(data, PROJECTS_COLLECTION, selectedProject!.id);
    console.log(`Updated doc ID ${selectedProject!.id} with ${JSON.stringify(data)}`);
  };

  return ( 
    <Container onChange={onSubmit}>
      {descriptor}
        {choices.map((choice: string) => (
          <label 
            key={`${fieldName}-${choice}-label`} 
            style={{verticalAlign: 'middle', marginRight: '0.4em', fontSize: '0.8em'}}
          >
            <input 
              type="radio" 
              value={choice} 
              name={fieldName} 
              key={`${fieldName}-${choice}`}
              checked={checked === choice}
              onChange={(e: any) => setChecked(e.target.value)}
              style={{verticalAlign: 'middle', ...radioStyles.current}} 
              disabled={disabled.current}
            />
            {choice}
          </label>
        ))}
    </Container>
  );
};

const Container = styled.div<{ rows?: number }>`
  display: flex;
  gap: 0.4em;
  ${({ rows }) => rows && rows > 1 ? 'flex-direction: column' : 'justify-content: flex-start; align-items: center;'}
`

const StyledInput = styled.textarea`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 0.8em;
  box-sizing: border-box;
  flex: 1;
  border: 0.08em solid ${Theme.colors.LightGray};
  border-radius: 0.2em;
  resize: none;
  
  :hover {
    border-color: ${Theme.colors.MidGray};
  }

  :focus {
    outline: none;
    border-color: ${Theme.colors.Main};
  }
`