import { useState, useEffect, FunctionComponent } from 'react';
import { omit, identity, pickBy, startCase } from 'lodash'
import { Button } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { PROJECTS_COLLECTION } from '../../../constants';
import { setDoc } from 'firebase/firestore';
import { createEmptyDocument, updateData } from '../../../firebase/firestore';
import { uploadFile, getDownloadURL } from '../../../firebase/storage';
import { useAuth } from '../../../firebase/auth';
import { useSelectedProjectContext } from '../../SelectedProjectContext';
import { ImageUpload } from '../../ImageUpload';

// TO DO: simplify this component.

const INPUT_FIELDS = [
  { name: 'registrationNumber', required: true },
  { name: 'title', required: true },
  { name: 'artist', required: true },
  { name: 'year', type: 'number', required: true },
  { name: 'other', required: false },
];

interface DialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FormFields {
  registrationNumber: string;
  title: string;
  artist: string;
  year: number;
  other?: string;
}

export const CreateOrEditDialog: FunctionComponent<DialogProps> = ({open, setOpen}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState<File>();
  const [imagePath, setImagePath] = useState<string>();
  const { authUser } = useAuth();
  const { selectedProject, setSelectedProject } = useSelectedProjectContext();

  useEffect(() => {
    if (selectedProject) setImagePath(selectedProject.imagePath);
    else setImagePath(undefined);
  }, [open]);
  
  const onSubmit = async (data: FormFields) => {
    setIsLoading(true);
    if (selectedProject) {
      let dataToWrite: any = data;
      if (image) {
        const imagePath = await getDownloadURL(await uploadFile(image, authUser!.uid, selectedProject.id, 'projectImage'));
        dataToWrite = { ...data, imagePath}
      }
      updateData(JSON.parse(JSON.stringify(dataToWrite)), PROJECTS_COLLECTION, selectedProject.id);
    }
    else {
      const docRef = createEmptyDocument(PROJECTS_COLLECTION);
      const imagePath = image ? await getDownloadURL(await uploadFile(image, authUser!.uid, docRef.id, 'projectImage')) : null;
      setDoc(docRef, pickBy({ uid: authUser?.uid, ...data, imagePath }, identity));
    }
    setImage(undefined);
    setImagePath(undefined);
    setIsLoading(false);
    onClose();
  };

  const handleImagePick = (event: any) => {
    const readInFiles = (event?.target as HTMLInputElement).files;
    if (readInFiles && readInFiles.length > 0) {
      setImage(readInFiles?.[0]);
      setImagePath(URL.createObjectURL(readInFiles[0]));
    }
  };

  const onClose = () => {
    setOpen(false); 
    if (selectedProject) {
      setSelectedProject(undefined);
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <FormContainer onSuccess={onSubmit} defaultValues={selectedProject ? omit(omit(omit(selectedProject, 'id'), 'imagePath'), 'uid'): {}}>
        <DialogContent>
          <div style={{display: 'flex', flexDirection: 'row', gap: '10px', paddingBottom: '10px'}}>
            <div style={{width: '66%'}}>
              <DialogTitle sx={{padding: '0 0 16px 0'}} >
                {selectedProject ? "Update" : "Create New"} Project
              </DialogTitle>
              <DialogContentText>
                Type in the main details of the artwork below. This information
                will help you identify projects on the home screen.
              </DialogContentText>
            </div>
            <ImageUpload filePath={imagePath} handleImagePick={handleImagePick} styles={{width: '34%', padding: 0}}/>
          </div>
          {INPUT_FIELDS.map((field) => (
            <TextFieldElement
              key={`project-form-input-${field.name}`}
              {...field}
              label={startCase(field.name)}
              fullWidth
              size="small"
              variant="standard"
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          {isLoading ? 
            <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
              {selectedProject ? "Update" : "Create"}
            </LoadingButton> :
            <Button type="submit">{selectedProject ? "Update" : "Create"}</Button>            
          }
        </DialogActions>
      </FormContainer>
    </Dialog>
  );
};
