import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteFile } from '../../../firebase/storage';
import { StorageReference } from 'firebase/storage';

interface Props {
  report: StorageReference;
  openDelete: boolean;
  setOpenDelete: React.Dispatch<React.SetStateAction<boolean>>;
  setChangesCounter: React.Dispatch<React.SetStateAction<number>>;
}

export const DeleteReportDialog = ({report, openDelete, setOpenDelete, setChangesCounter}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    await deleteFile(report);
    await setChangesCounter((changesCounter: number) => changesCounter + 1);
    setIsLoading(false);
    onClose();
  }

  const onClose = () => {
    setOpenDelete(false); 
  }

  return (
    <Dialog open={openDelete} onClose={onClose} >
      <DialogTitle>Report Deletion</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete <b>{report.name.split('---')[1]}</b>? You can't undo this action.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        { !isLoading ? 
          <Button type="submit" variant="contained" color="error" startIcon={<DeleteIcon />} onClick={handleDelete}>
            Delete
          </Button> :
          <LoadingButton loading loadingPosition="start" startIcon={<DeleteIcon />} variant="outlined">
            Delete
          </LoadingButton>
        }
      </DialogActions>
    </Dialog>
  )
}