import { initializeApp } from "firebase/app";
import { getAuth} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCDCexN4dzUAyyPhTy8c69YX1KJsC9qyVc",
  authDomain: "restorify-bf0f8.firebaseapp.com",
  projectId: "restorify-bf0f8",
  storageBucket: "restorify-bf0f8.appspot.com",
  messagingSenderId: "761451715485",
  appId: "1:761451715485:web:9ef8ae94aa64ed14aaad74",
  measurementId: "G-FHCD1PR689"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore();
export const storage = getStorage();
