import { Navigate } from "react-router-dom";
import { useAuth } from '../../firebase/auth';
import { CircularProgress } from '@mui/material';

export const PrivateRoute = ({ children }: any) => {
    const { authUser, isLoading } = useAuth();

    if (isLoading) return <CircularProgress/>;

    return authUser ? children : <Navigate to='/login'/>
}