import { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { User } from './ShareDialog';

interface Props {
  setPermissionedUsers: React.Dispatch<React.SetStateAction<User[]>>;
  otherUsers: User[];
  setOtherUsers: React.Dispatch<React.SetStateAction<User[]>>;
  setChangesPending: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserSelect = ({setPermissionedUsers, otherUsers, setOtherUsers, setChangesPending}: Props) => {
  // https://stackoverflow.com/questions/59790956/material-ui-autocomplete-clear-value
  const [numUsersSelected, setNumUsersSelected] = useState(0); 
  
  const handleUserSelect = (event: any, user: User | null) => {
    if (user) {
      const userWithRole: User = {...user, role: 'viewer'};
      setPermissionedUsers(users => [userWithRole, ...users]);
      setOtherUsers(users => users.filter(user => user.id !== userWithRole.id));
      setNumUsersSelected(value => value + 1);
      setChangesPending(true);
    }
  }

  return (
    <Autocomplete
      size="small"
      sx={{padding: '10px 0'}}
      key={`autocomplete-${numUsersSelected}`}
      options={otherUsers}
      getOptionLabel={user => user.email}
      filterSelectedOptions
      onChange={handleUserSelect}
      renderInput={(params) => (
        <TextField {...params} 
          inputRef={input => input && input.focus()}
          variant="outlined" 
          label="Share with"
          placeholder="Select people"
        />
      )}
    />
  );
}