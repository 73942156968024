import { uploadBytes, ref, getDownloadURL as getStorageDownloadURL, deleteObject, listAll, StorageReference } from 'firebase/storage';
import { storage } from './firebase';

const BUCKET_URL = 'gs://restorify-bf0f8.appspot.com';

export const uploadFile = async (file: File, uid: string, docId: string, fileName: string) => {
  const ext = file.name.split('.').pop()
  const savePath = `${BUCKET_URL}/${uid}/${docId}---${fileName}.${ext}`;
  await uploadBytes(ref(storage, savePath), file);
  console.log(`Uploaded file to ${savePath}`);
  return savePath;
}

export const uploadOrgFile = async (file: File, orgId: string, fileName: string) => {
  const ext = file.name.split('.').pop()
  const savePath = `${BUCKET_URL}/organizations/${orgId}---${fileName}.${ext}`;
  await uploadBytes(ref(storage, savePath), file);
  console.log(`Uploaded file to ${savePath}`);
  return savePath;
}

export const getDownloadURL = async (path: string) => {
  return await getStorageDownloadURL(ref(storage, path));
}

export const getDocumentFiles = async (userId: string, docId: string) => {
  const userFiles = ref(storage, userId);
  const files = (await listAll(userFiles)).items;
  const documentFiles = files.filter(file => file.fullPath.includes(docId));
  return documentFiles
}

export const deleteFile = async (fileRef: StorageReference) => {
  deleteObject(fileRef).then(() => {
    console.log(`Deleted ${fileRef} succesfully`);
  }).catch((error) => {
    console.log(`Error when deleting ${fileRef}. Error msg: ${error}`);
  });
}

export const deleteDocumentFiles = async (userId: string, docId: string) => {
  const documentFiles = await getDocumentFiles(userId, docId);
  const fileNamesToDelete = documentFiles.map(file => file.fullPath);

  fileNamesToDelete.forEach((fileName: string) => {
    deleteObject(ref(storage, fileName)).then(() => {
      console.log(`Deleted ${fileName} succesfully`);
    }).catch((error) => {
      console.log(`Failed to delete ${fileName}. Error msg: ${error}`);
    });
  });
  
}