import { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';
import { LoginPage } from './components/LoginPage';
import { Layout } from './components/layout/Layout';
import { ProjectsPage } from './components/projects/ProjectsPage';
import { ReportsPage } from './components/reports/ReportsPage';
import { TrashPage } from './components/trash/TrashPage';
import { NotFoundPage } from './components/NotFoundPage';

export const homePath = '/';
export const loginPath = '/login';
export const reportsPath = '/reports';
export const trashPath = '/trash';

const App: FunctionComponent = () => {
  return (
    <Routes>
      <Route path={loginPath} element={<LoginPage/>}/>
      <Route path={homePath} element={<Layout/>}>
        <Route index path={homePath} element={<ProjectsPage/>} />
        <Route path={reportsPath+'/*'} element={<ReportsPage/>} />
        <Route path={trashPath} element={<TrashPage/>} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}

export default App;
