import { useState, useEffect } from 'react';
import { ProjectsPageContainer, ProjectsTopBar, ItemGrid, Heading } from '../projects/ProjectsPage';
import { SearchField } from '../projects/SearchField';
import { ItemThumbnail } from '../projects/ItemThumbnail';
import { DeleteDialog } from '../projects/dialogs/DeleteDialog';
import { RestoreDialog } from './RestoreDialog';
import { useAuth } from '../../firebase/auth';
import { TRASH_COLLECTION, Project } from '../../constants';
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from '../../firebase/firebase';

export const TrashPage = () => {
  const [openRestore, setOpenRestore] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [projects, setProjects] = useState<Project[]>([]);
  const [sharedProjects, setSharedProjects] = useState<Project[]>([]);
  const { authUser } = useAuth();

  useEffect(() => {
    let unsub1 = () => {};
    let unsub2 = () => {};

    if (authUser) {
      const colRef = collection(db, TRASH_COLLECTION)

      unsub1 = onSnapshot(query(colRef, where('uid', '==', authUser.uid)), (querySnapshot) => {
        const _projects: Project[] = querySnapshot.docs.map((doc: any) => {return {...doc.data(), id: doc.id}});
        setProjects(_projects);
      });

      unsub2 = onSnapshot(query(colRef, where('uid', '!=', authUser.uid)), (querySnapshot) => {
        const _projects: Project[] = querySnapshot.docs.map((doc: any) => {return {...doc.data(), id: doc.id}});
        setSharedProjects(_projects.filter(project => {
          const permissions = project?.projectPermissions 
          return (
            permissions && 
            authUser.uid in permissions &&
            permissions[authUser.uid as keyof typeof permissions] === "owner"
          )
        }));
      });
    }

    return () => {unsub1(); unsub2();}
  }, []);

  const generateItemGrid: Function = (projects: Project[]) => {
    if (!authUser) return undefined;
    return (
      <ItemGrid>
        {projects.length !== 0 ? 
          projects.filter(
            (project: any) => JSON.stringify(project).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
          ).map((project: any) => (
            <ItemThumbnail 
              project={project} 
              setOpenRestore={setOpenRestore}
              setOpenDelete={setOpenDelete}
              blockNavigation
              key={`trash-${project.id}`}
            />
          )) :
          <h4 style={{paddingLeft: '15px'}}>No projects in the trash at the moment...</h4>
        }
      </ItemGrid>
    )
  }

  return (
    <ProjectsPageContainer>
      <ProjectsTopBar>
        <Heading>Projects in Trash</Heading>
        <SearchField setSearchTerm={setSearchTerm}/>
      </ProjectsTopBar>
      {generateItemGrid(projects)}
      {sharedProjects.length > 0 ? <Heading>Projects shared with me</Heading> : ''}
      {sharedProjects.length > 0 ? generateItemGrid(sharedProjects): ''}
      <RestoreDialog open={openRestore} setOpen={setOpenRestore}/>
      <DeleteDialog permanentDelete open={openDelete} setOpen={setOpenDelete}/>
    </ProjectsPageContainer>
  );
}
