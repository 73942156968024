
import { FunctionComponent } from 'react';
import { TextField } from '@mui/material';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

interface Props {
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
}

export const SearchField: FunctionComponent<Props> = ({ setSearchTerm }) => {
  return (
    <TextField
      variant="outlined"
      placeholder="Search"
      size="small"
      onChange={(event: any) => setSearchTerm(event.target.value)}
      InputProps={{startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>}}
      sx={{width: 'clamp(115px, 25vw, 230px)'}}
    />
  );
}