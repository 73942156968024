import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { saveAs } from 'file-saver';
import { getBlob } from 'firebase/storage';
import { Theme, getUserRole } from '../../constants';
import { topBarSidePaddingPerc, topBarHeight, navBarWidth } from '../layout/Layout';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { CreateReportButton } from './dialogs/CreateReportButton';
import { useAuth } from '../../firebase/auth';
import { getDocumentFiles } from '../../firebase/storage';
import { useSelectedProjectContext } from '../SelectedProjectContext';
import { StorageReference } from 'firebase/storage';
import * as AIIcons from 'react-icons/ai';
import { DeleteReportDialog } from './dialogs/DeleteReportDialog';

// TO DO: make the NavBar a drawer on mobile.
// check that we don't have the cutoff issues on scroll on this navbar
// ecccccchhh reports background should be a snapshot of the report - react-pdf.

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ReportsNavBar = ({ open, setOpen }: Props) => {
  const [reports, setReports] = useState<StorageReference[]>([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [changesCounter, setChangesCounter] = useState(0);
  const { authUser } = useAuth();
  const { selectedProject } = useSelectedProjectContext();
  const [selectedReport, setSelectedReport] = useState<StorageReference>();
  const isViewer = useRef(authUser && selectedProject ? 
    getUserRole(authUser!.uid, selectedProject) === 'viewer' : false 
  );

  useEffect(() => {
    const loadReports = async () => {
      if (authUser && selectedProject) {
        const files = await getDocumentFiles(authUser.uid, selectedProject.id);
        setReports(files.filter(file => file.name.includes('.pdf') || file.name.includes('.docx')));
      }
    }
    loadReports();
  }, [changesCounter]);

  const onClick = (event: any) => {
    event.currentTarget.focus();
    //display the relevant item on the screen. useEffect?
  };

  const handleReportDownload = async (report: StorageReference) => {
    saveAs(await getBlob(report), report.name.split('---')[1]);
  };

  return (
    <Drawer variant="persistent" anchor="left" open={open}
      sx={{
        width: `${navBarWidth}%`,
        '& .MuiDrawer-paper': {
          width: `${navBarWidth}%`,
          boxSizing: 'border-box',
          backgroundColor: Theme.colors.DarkGray
      }}}
    >
      <ButtonContainer style={{marginTop: topBarHeight, paddingLeft: `${topBarSidePaddingPerc}%`}}>
        <IconButton sx={{paddingLeft: 0}} onClick={() => setOpen(false)}><MenuIcon/></IconButton>
        {isViewer.current ? '': <CreateReportButton setChangesCounter={setChangesCounter}/>}
      </ButtonContainer>
      <ReportContainer> 
        {reports.length === 0 ? 'No other reports': reports.map((report: any, idx: any) => (
          <Report tabIndex={idx} key={`report-${report.fullPath}`} onClick={onClick}>
            <ReportName>{report.name.split('---')[1]}</ReportName>
            <IconDiv onClick={() => handleReportDownload(report)}>
              <AIIcons.AiOutlineDownload size={20}/>
            </IconDiv>
            <IconDiv onClick={() => {setOpenDelete(true); setSelectedReport(report);}}>
              <AIIcons.AiOutlineDelete size={20}/>
            </IconDiv>
          </Report>
        ))}
        {selectedReport ? <DeleteReportDialog
          report={selectedReport}
          openDelete={openDelete}
          setOpenDelete={setOpenDelete}
          setChangesCounter={setChangesCounter}
        /> : ''}
      </ReportContainer>
    </Drawer>
  );
}

const ButtonContainer = styled.header`
  display: flex;
  justify-content: 'space-between';
  align-items: 'center';
  background-color: ${Theme.colors.DarkGray};
  position: fixed;
`;

const ReportContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: 'space-between';
  align-items: center;
  padding: 5% 0;
  gap: 20px;
  height: 100%;
  margin-top: 100px;
`;

const ReportName = styled.div`
  width: 90%;
  overflow: hidden;
`

const Report = styled.div`
  display: flex;  
  width: 70%;
  height: 80px; 
  background-color: ${Theme.colors.OffWhite};
  border-radius: 5px;
  font-size: 12px;
  padding: 5px;

  :hover {
    -webkit-filter: brightness(90%); 
  }

  :focus {
    border: 3px solid ${Theme.colors.Green}
  }
`;

const IconDiv = styled.div`
  color: ${Theme.colors.TheDarkest};
  height: 25%;
  :hover {
    color: red;
  }
`