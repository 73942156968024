import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { db } from '../../../firebase/firebase';
import { useAuth } from '../../../firebase/auth';
import { doc, deleteDoc } from "firebase/firestore";
import { deleteDocumentFiles } from '../../../firebase/storage';
import { PROJECTS_COLLECTION, TRASH_COLLECTION } from '../../../constants';
import { useSelectedProjectContext } from '../../SelectedProjectContext';
import { moveData } from '../../../firebase/firestore';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  permanentDelete?: boolean; // false -> moves document to trash and keeps files. true -> deletes all associated data.
}

export const DeleteDialog = ({open, setOpen, permanentDelete}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { selectedProject, setSelectedProject } = useSelectedProjectContext();
  const { authUser } = useAuth();

  const handleDelete = async () => {
    setIsLoading(true);

    if (permanentDelete) {
      await deleteDoc(doc(db, TRASH_COLLECTION, selectedProject!.id));
      deleteDocumentFiles(authUser!.uid, selectedProject!.id);
    } else {
      await moveData(selectedProject!.id, PROJECTS_COLLECTION, TRASH_COLLECTION);
    }
    console.log(`Deleted project ID (${permanentDelete ? 'permanently': 'to trash'}): ${selectedProject!.id}`);
    setIsLoading(false);
    onClose();
  }

  const onClose = () => {
    setOpen(false); 
    if (selectedProject) {
      setSelectedProject(undefined);
    }
  }

  return (
    <Dialog open={open} onClose={onClose} >
      <DialogTitle>{permanentDelete? 'Project Deletion': 'Move to trash'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to {permanentDelete? "delete this project? You can't undo this action.": "move this to trash?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        { isLoading ? 
          <LoadingButton loading loadingPosition="start" startIcon={<DeleteIcon />} variant="outlined">
            Delete
          </LoadingButton> :
          <Button type="submit" variant="contained" color="error" startIcon={<DeleteIcon />} onClick={handleDelete}>
            Delete
          </Button> 
        }
      </DialogActions>
    </Dialog>
  )
}